import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import '../styles.scss'

const CampanhaQualidade = () => {
  return (
    <>
      <section className="section-services gift-card_container w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar CartaoPresente-> */}
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- CartaoPresente Products-> */}
              <div className="services-content px-3">
                <div className="section__top align-center">
                  <h1 className="text-blue italic text-2xl font-bold text-center flex items-center justify-center">
                    CAMPANHA DE QUALIDADE
                  </h1>

                  <h2 className="section-title__text">
                    CAMPANHA DE QUALIDADE: KIPRUN GPS
                  </h2>

                  <p className="text-left text-sm mt-4">
                    Caro(a) Cliente Esportista, se você tiver adquirido os
                    seguintes produtos KIPRUN GPS 500 ou KIPRUN GPS 550, você
                    precisa ter ciência dessas instruções:
                  </p>

                  <p className="text-left text-sm mt-4">
                    Você não deve mais utilizar este produto
                  </p>
                  <p className="text-left text-sm mt-4">
                    A nossa equipe técnica global identificou o risco da bateria
                    superaquecer durante o processo de carregamento ou com o
                    tempo de uso. Dessa forma, pedimos que ele seja devolvido à{' '}
                    <a
                      href="https://www.decathlon.com.br/lojas"
                      target="_blank"
                      rel="noreferrer"
                    >
                      loja
                    </a>{' '}
                    e não seja mais utilizado. Sinalizamos que este processo é
                    preventivo, porém de grande importância.
                  </p>

                  <h3 className="text-gray italic text-lg font-semibold text-left text-sm mt-4">
                    Descrição:
                  </h3>

                  <ul className="list-product-kiprun text-left pl-10 text-sm mt-4">
                    <li>Nome do produto: Kiprun GPS 500 e Kiprun GPS 550</li>
                    <li>Marca: Kiprun</li>
                    <li>
                      Referência do produto: 8527648, 8527647, 8527651 e 8527652
                    </li>
                    <li>Cores: Preto, verde e branca</li>
                  </ul>

                  <img
                    className="list-product-kiprun-img"
                    src="https://decathlonstore.vteximg.com.br/arquivos/ids/9032905/imagem-de-uso-do-produto.png?v=637822511844400000"
                    alt=""
                  />

                  <p className="text-left text-sm mt-4">
                    Para sua segurança e comodidade, nossa Central de
                    Atendimento está disponível para responder todas as
                    perguntas sobre esse assunto.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CampanhaQualidade
