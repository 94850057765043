import { GatsbySeo } from 'gatsby-plugin-next-seo'
import CampanhaQualidade from 'src/components/servicos/ServicesPage/CampanhaQualidade/CampanhaQualidade'

function Page() {
  return (
    <>
      <GatsbySeo title="Campanha de Qualidade | Decathlon" />
      <CampanhaQualidade />
    </>
  )
}

export default Page
